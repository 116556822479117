var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { staticClass: "h-1", attrs: { viewBox: "0 0 24 24" } }, [
    _c("path", {
      staticClass: "fill-current",
      attrs: {
        d: "M12 10c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2zm0-6c-1.1 0-2 \n  .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2zm0 12c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }